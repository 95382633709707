import { useState, useContext } from 'react';
import './StartPage.css';
import { ContactInfoContext } from '../../contexts/ContactInfoContext';
import logo from '../../ingager-logo.png'; // Tell webpack this JS file uses this image

function StartPage({onStart}) {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const contactInfo = useContext(ContactInfoContext);

  const { dispatchUserEvent } = useContext(ContactInfoContext);
  const handleSubmit = (event) => {
    event.preventDefault(); 
    if(event.target.name.value === "" || event.target.company_name.value === ""  || event.target.terms.checked === false){
      setShowErrorMessage(true);
    }
    else{
      setShowErrorMessage(false);
      dispatchUserEvent(
        { 
          name: event.target.name.value, 
          company_name: event.target.company_name.value, 
          termsChecked: event.target.terms.value
        }
      );
    }
  };

  return (
    <div id="start-page">
      <h1 id="title">Välkommen till vår kundundersökning!</h1>
      <p id="intro-text">Den tar endast någon minut att fylla i och ger oss värdefull feedback kring hur vi kan säkerställa bästa möjliga resultat för dig och din verksamhet. Tack för att du tar dig tid!</p>
      
      <form onSubmit={handleSubmit}>
        <label>
          <input type="text" defaultValue={contactInfo.contactInfo.name} placeholder="Namn" name="name" required />
        </label>

        <label>
          <input type="text" defaultValue={contactInfo.contactInfo.company_name} placeholder="Företagsnamn" name="company_name" required />
        </label>
        
        {showErrorMessage && (
          <p id="error-message">Var god fyll i alla fält</p>
        )}
        <label id="terms"><input type="checkbox" name="terms" id="terms-checkbox" required  defaultChecked={contactInfo.contactInfo.termsChecked}/> Jag accepterar <a href="https://ingager.com/privacy-policy" target="_blank" rel="noreferrer">integritetspolicyn</a>*</label>
        <button type="submit" id="start-button">Start</button>
      </form>
      <img src={logo} alt="Ingager logo" id="logo"/>
    </div>
  );
}

export default StartPage;