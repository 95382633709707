import { useContext, useState } from 'react';
import './EndPage.css';
import { AnswersContext } from '../../contexts/AnswersContext';
import { QuizContext } from '../../contexts/QuizContext';
import { ContactInfoContext } from '../../contexts/ContactInfoContext';

function EndPage({onBack}) {
  const { answers, dispatchAnswerEvent } = useContext(AnswersContext);
  const quiz = useContext(QuizContext);
  const contactInfo = useContext(ContactInfoContext);
  const [formStatus, setFormStatus] = useState("");
  const currentTime = new Date().getHours();

  let timeOfDay;
  if (currentTime < 9) {
    timeOfDay = 'morgon';
  } else if (currentTime < 12) {
    timeOfDay = 'dag';
  } else if (currentTime < 17) {
    timeOfDay = 'eftermiddag';
  } else {
    timeOfDay = 'kväll';
  }

  const handleMessageChange = (event) => {
    dispatchAnswerEvent(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const questions = quiz.questions.map(obj => obj.question);

    questions.push("Message");
    const data = {
      contactInfo: contactInfo.contactInfo,
      answers: answers,
      questions: questions
    }

    fetch('insert.php', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', },
      body: JSON.stringify(data)
    })
    .then(
      setFormStatus("success")
    )
    .catch((error) => {
      setFormStatus("error");
    });

    let zapData = [...answers]
    zapData.unshift(contactInfo.contactInfo.company_name);
    zapData.unshift(contactInfo.contactInfo.name);
    zapData = { ...zapData }
    
    // Send to zapier
    fetch('https://hooks.zapier.com/hooks/catch/14815318/3ulak2q/', {
      method: 'POST',
      body: JSON.stringify(zapData)
    })
    .then(response => response.json())
    .then(data => {
    })
    .catch((error) => {
    });
  };


  return (
    <div id="end-page"> 
      {formStatus !== "success" && formStatus !== "error" &&
        <>
          <h1 id="title">Finns det något annat som du skulle vilja tillägga för att ytterligare förstärka vårt samarbete framöver?</h1>
          <form onSubmit={handleSubmit}>
            <textarea id="message" rows="10" maxLength="250" name="message" placeholder="Meddelande"  onChange={handleMessageChange} defaultValue={answers[quiz.page]}></textarea>
            <div id="navigation-buttons">
              <button id="back-button" onClick={e => onBack()}>Föregående</button>
              <button type="submit" id="next-button" >Skicka in svar</button>
            </div>
          </form>
        </>
      }
      {formStatus === "error" && 
        <p id="error">Något gick fel, var vänlig fösök igen.</p>
      }
      {formStatus === "success" && 
      <div id="thank-you">
        <h1 id="thank-you-title">Tack <span id="name">{contactInfo.contactInfo.name}</span>!</h1>
        <h2>Ha en bra <span id="date">{timeOfDay}</span>.</h2>
        <a target="_blank" rel="noreferrer" href="https://ingager.com/">ingager.com</a>
      </div>
      }
    </div>
  );
}

export default EndPage;