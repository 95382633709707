import { useContext } from 'react';
import './BigRoundButtons.css';
import { QuizContext } from '../../contexts/QuizContext';
import { AnswersContext } from '../../contexts/AnswersContext';

function BigRoundButtons({question}) {  
  const quiz = useContext(QuizContext);
  const answers = useContext(AnswersContext);
  const { dispatchAnswerEvent } = useContext(AnswersContext);

  const handleAnswer = (answer) => {
    dispatchAnswerEvent(answer);
  };

  return (
    <div id="big-round-buttons">
      {question.options.map((option, index) => (
        <div className={answers.answers[quiz.page] === option.text ? "big-round-button selected" : "big-round-button"} key={quiz.page+"-"+index} onClick={e => handleAnswer(option.text)} style={{backgroundColor: option.color}}>{option.text}</div>
      ))}
    </div>
  );
}

export default BigRoundButtons;