import { useContext } from 'react';
import './NumberButtons.css';
import { QuizContext } from '../../contexts/QuizContext';
import { AnswersContext } from '../../contexts/AnswersContext';

function NumberButtons({question}) {  
  const quiz = useContext(QuizContext);
  const answers = useContext(AnswersContext);
  const { dispatchAnswerEvent } = useContext(AnswersContext);

  const handleAnswer = (answer) => {
    dispatchAnswerEvent(answer);
  };

  return (
    <div id="number-buttons">
      {question.options.map((option, index) => (
        <div className={answers.answers[quiz.page] === option ? "number-button selected" : "number-button"} key={quiz.page+"-"+index} onClick={e => handleAnswer(option)}>{option}</div>
      ))}
    </div>
  );
}

export default NumberButtons;