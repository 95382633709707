import { useContext } from 'react';
import './FourStepButtons.css';
import { QuizContext } from '../../contexts/QuizContext';
import { AnswersContext } from '../../contexts/AnswersContext';

function FourStepButtons({question}) {  
  const quiz = useContext(QuizContext);
  const answers = useContext(AnswersContext);
  const { dispatchAnswerEvent } = useContext(AnswersContext);

  const handleAnswer = (answer) => {
    dispatchAnswerEvent(answer);
  };

  return (
    <div id="four-step-buttons">
      {question.options.map((option, index) => (
        <div className={answers.answers[quiz.page] === option ? "four-step-button selected" : "four-step-button"} key={quiz.page+"-"+index} onClick={e => handleAnswer(option)}>{option}</div>
      ))}
    </div>
  );
}

export default FourStepButtons;