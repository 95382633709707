import { useContext } from 'react';
import './Quiz.css';
import NumberButtons from './NumberButtons';
import { QuizContext } from '../../contexts/QuizContext';
import { AnswersContext } from '../../contexts/AnswersContext';
import BigRoundButtons from './BigRoundButtons';
import FourStepButtons from './FourStepButtons';

function Quiz({onNext, onBack}) {
  const quiz = useContext(QuizContext);
  const { answers, dispatchAnswerEvent } = useContext(AnswersContext);
  const question = quiz.questions[quiz.page];

  const handleNoAnswer = () => {
    dispatchAnswerEvent("Inget svar");
  };

  return (
    <div id="quiz">
      <h1 id="question-title">{question.question}</h1>
      {
        {
          'NumberButtons': <NumberButtons question={question}/>,
          'BigRoundButtons': <BigRoundButtons question={question}/>,
          'FourStepButtons': <FourStepButtons question={question}/>
        }[question.type]
      }
      <div id="navigation-buttons" className={question.noAnswer === true ? "has-no-answer" : ""}>
        <button id="back-button" onClick={e => onBack()}>Föregående</button>
        {question.noAnswer && (
          <button id="no-answer" className={answers[quiz.page] === "No answer" ? "selected" : ""} onClick={e => handleNoAnswer()}>Inget svar</button>
        )}
        <button id="next-button" onClick={e => onNext()}>Nästa</button>
      </div>
    </div>
  );
}

export default Quiz;