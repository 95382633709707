import { useState } from 'react';
import './App.css';
import Quiz from './components/quiz/Quiz';
import StartPage from './components/startPage/StartPage';
import EndPage from './components/endPage/EndPage';
import { QuizContext } from './contexts/QuizContext';
import { AnswersContext } from './contexts/AnswersContext';
import { ContactInfoContext } from './contexts/ContactInfoContext';

function App() {
  const [page, setPage] = useState(0);
  const [quizStarted, setQuizStart] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [contactInfo, setUserInfo] = useState({});
  
  const questions = [
    {
      question: "Hur nöjd är du med vårt samarbete överlag?",
      type: "FourStepButtons",
      options: ["Missnöjd", "Delvis nöjd", "Nöjd", "Mycket nöjd"],
      noAnswer: false
    },
    {
      question: "Hur väl möter resultaten från våra tjänster dina förväntningar?",
      type: "FourStepButtons",
      options: ["Inte väl", "Delvis väl", "Väl", "Mycket väl"],
      noAnswer: false
    },
    {
      question: "Hur skulle du bedöma vårt gemensamma arbetssätt och processer?",
      type: "FourStepButtons",
      options: ["Inte bra", "Delvis bra", "Bra", "Mycket bra"],
      noAnswer: false
    },
    {
      question: "Hur väl fungerar kommunikationen mellan dig och din kontaktperson på Ingager?",
      type: "FourStepButtons",
      options: ["Inte bra", "Delvis bra", "Bra", "Mycket bra"],
      noAnswer: false
    },
    {
      question: "Hur pass proaktiva upplever du att vi är som samarbetspartner?",
      type: "FourStepButtons",
      options: ["Ej proaktiva", "Delvis proaktiva", "Proaktiva", "Mycket proaktiva"],
      noAnswer: false
    },
    {
      question: "Hur upplever du vår förmåga att anpassa våra tjänster efter era behov?",
      type: "FourStepButtons",
      options: ["Inte bra", "Delvis bra", "Bra", "Mycket bra"],
      noAnswer: false
    },
    {
      question: "Hur nöjd är du med rapporteringen av resultat från våra tjänster?",
      type: "FourStepButtons",
      options: ["Missnöjd", "Delvis nöjd", "Nöjd", "Mycket nöjd"],
      noAnswer: false
    },
    {
      question: "Hur skulle du bedöma vår affärsmässiga förståelse för ditt företag och dina mål?",
      type: "FourStepButtons",
      options: ["Inte bra", "Delvis bra", "Bra", "Mycket bra"],
      noAnswer: false
    },
    {
      question: "Hur upplever du vår förmåga att tillföra värde baserat på vår expertis?",
      type: "FourStepButtons",
      options: ["Inte bra", "Delvis bra", "Bra", "Mycket bra"],
      noAnswer: false
    },
    {
      question: "Hur sannolikt är det att du skulle rekommendera Ingager till ditt nätverk?",
      type: "FourStepButtons",
      options: ["Osannolikt", "Delvis sannolikt", "Sannolikt", "Mycket sannonlikt"],
      noAnswer: false
    }
    
  ];

  const dispatchAnswerEvent = (answer) => {
    const newAnswers = [...answers];
    newAnswers[page] = answer;
    setAnswers(newAnswers);
  };

  const dispatchUserEvent = (info) => {
    setQuizStart(true);
    setUserInfo(info);  
  };

  function next() {
    if(answers[page]){
      setPage(page + 1);
    }
  }

  function back() {
    if(page > 0){
      setPage(page - 1);
    }
    else{
      setQuizStart(false);
    }
  }

  return (
    <div id="app">
      <QuizContext.Provider value={{questions, page}}>
        <ContactInfoContext.Provider value={{ contactInfo, dispatchUserEvent }}>
          <AnswersContext.Provider value={{ answers, dispatchAnswerEvent }}>
            {!quizStarted && page === 0 && (
              <StartPage />
            )}
            {quizStarted && page < questions.length && (
              <Quiz questions={questions} page={page} onNext={next} onBack={back}/>
            )}
            {page >= questions.length && (
              <EndPage onBack={back} contactInfo={contactInfo}/>
            )}
          </AnswersContext.Provider>
        </ContactInfoContext.Provider>
      </QuizContext.Provider>
    </div>
  );
}

export default App;
